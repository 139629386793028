:root, [data-amplify-theme]{
	--amplify-primary-color: #5664d2;
	--amplify-primary-shade: rgb(60, 70, 147);
	--amplify-primary-tint: #5664d2;
	--amplify-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	--amplify-red: #f50057;
}

amplify-authenticator {
	--border-radius: 16px;
	--box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 2px 0 rgba(63, 63, 68, 0.15);
}

@media (min-width: 600px) {
	.amplify-field {
		width: 100%;
		min-width: 50%;
	}

	[data-amplify-authenticator-signup] fieldset.amplify-flex > div.amplify-flex {
		display: flex;
		flex-direction: initial !important;
		flex-wrap: wrap !important;
		/*grid-template-columns: repeat(2, 1fr);*/
	}

	[data-amplify-authenticator-signup] fieldset.amplify-flex > div.amplify-flex > div {
		width: calc(100% / 2 - var(--amplify-components-flex-gap) / 2);
	}

	[data-amplify-authenticator-signup] fieldset.amplify-flex > div.amplify-flex > div:nth-child(1),
	[data-amplify-authenticator-signup] fieldset.amplify-flex > div.amplify-flex > div:nth-child(6),
	[data-amplify-authenticator-signup]
	fieldset.amplify-flex
	> div.amplify-flex
	> div:nth-child(7) {
		width: 100%;
	}
}
